import React, { ReactNode, useEffect, useRef } from "react"

// @ts-ignore
import { ReactComponent as TicketClip } from "assets/images/ticket-clip.svg"
// @ts-ignore
// import { ReactComponent as TicketBg } from "assets/images/full-ticket.svg"
import TicketBg from "assets/images/ticket-grad.png"
// @ts-ignore
// import { ReactComponent as TicketBgNoGrad } from "assets/images/full-ticket-no-grad.svg"
import TicketBgNoGrad from "assets/images/ticket-nograd.png"

import s from "./FullTicket.module.scss"

export default function FullTicket({
  gradient = false,
  children,
}: {
  gradient?: boolean
  children?: ReactNode
}) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function sizeContainer() {
      if (containerRef.current) {
        containerRef.current.style.width = `${window.innerWidth}px`
        containerRef.current.style.height = `${window.innerWidth * 1.7}px`

        if (parseInt(containerRef.current.style.height) > window.innerHeight) {
          containerRef.current.style.height = `${window.innerHeight}px`
          containerRef.current.style.width = `${window.innerHeight / 1.7}px`
        }
      }
    }
    sizeContainer()
    window.addEventListener("resize", sizeContainer)
    return () => {
      window.removeEventListener("resize", sizeContainer)
    }
  }, [])
  return (
    <div className={s.fullTicket}>
      <TicketClip />
      <div ref={containerRef} className={s.ticketInner}>
        <div className={s.ticketChildren}>
          {gradient ? (
            // <TicketBg className={s.ticketBg} />
            <img className={s.ticketBg} src={TicketBg} alt="" />
          ) : (
            // <TicketBgNoGrad className={s.ticketBg} />
            <img className={s.ticketBg} src={TicketBgNoGrad} alt="" />
          )}
          <div className={s.childrenWrapper}>
            <div className={s.children}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
