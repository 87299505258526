import React, { ReactNode } from "react"

import s from "./Modal.module.scss"

export default function Modal({
  children,
  transparent = false,
}: {
  children: ReactNode
  transparent?: boolean
}) {
  return (
    <div className={`${s.Modal} ${transparent && s.transparent}`}>
      {children}
    </div>
  )
}
