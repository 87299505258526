import React from "react"
import ReactDOM from "react-dom/client"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter as Router } from "react-router-dom"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "normalize.css"
import "styles/index.scss"
import App from "./App"

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || ""

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
