import React, { useEffect } from "react"
import ReactGA from "react-ga4"
import { Route, Routes, useLocation } from "react-router-dom"

import Loading from "components/Loading"

/* Importing with React.lazy adds basic code-splitting */
const Home = React.lazy(() => import("views/Home"))
const Capture = React.lazy(() => import("views/Capture"))
const Filter = React.lazy(() => import("views/Filter"))
const Share = React.lazy(() => import("views/Share"))
const NotFound = React.lazy(() => import("views/NotFound"))

function App() {
  const location = useLocation()

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      ReactGA.initialize("G-CDM2173MQF")
      console.log("DEVELOPMENT")
    } else {
      ReactGA.initialize("G-CDM2173MQF")
      console.log("PRODUCTION")
    }
  }, [])

  /* Send GA events on route change */
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname })
  }, [location])

  return (
    <main className="App">
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/take-photo" element={<Capture />} />
          <Route path="/filter" element={<Filter />} />
          <Route path="/share" element={<Share />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </main>
  )
}

export default App
