import gsap from "gsap"
import React, { ReactNode, useEffect, useRef, useState } from "react"

import FullTicket from "components/FullTicket"
import Modal from "components/Modal"

import logo from "assets/images/logo.png"

import s from "./Loading.module.scss"

function Loading() {
  const [currentMessage, setCurrentMessage] = useState(0)
  const message = useRef(null)
  const messages = [
    "Hold tight, we're installing some Christmas cheer.",
    "Adding the final touches to your magical holiday movie moment",
    "Hold tight, installing some Christmas cheer.",
    "Please keep your hands inside the sleigh at all times",
    "Shaking won't load any faster, it's not a snow globe.",
    "So...got your dad another tie for Christmas, huh?",
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      const tl = gsap.timeline()
      tl.fromTo(
        message.current,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            setCurrentMessage((state) => {
              if (state < messages.length - 1) {
                return state + 1
              } else {
                return 0
              }
            })
          },
        },
      ).fromTo(
        message.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
        },
      )
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Modal>
      <FullTicket gradient>
        <div className={s.Loading}>
          <img src={logo} alt="" className={s.logo} />
          <div className={s.loadingDescription}>
            <h2>
              Loading<span>...</span>
            </h2>
            <p ref={message}>{messages[currentMessage]}</p>
          </div>
        </div>
      </FullTicket>
    </Modal>
  )
}

export default React.memo(Loading)
